/**
 * Gestion des erreurs et du submit du formulaire de commentaire en Ajax sinon on a pas de vrai gestion d'erreur ni de message "form submited"
 * */
if ($('.comment-form').length > 0) {
    var commentForm = $('.comment-form'),
        formerror = false, // SI on a au moins une erreur dans le form on met error à true et on submit pas
        errorMessage = '<span class="frm_error">Ce champ est obligatoire</span>',
        errorMessageMail = '<span class="frm_error">L\'e-mail doit être valide</span>',
        succesMessage = '<p class="success">Merci pour votre commentaire</p>';

    // Init de le liste des champs obligatoires
    var fields = {
        "email": {
            "obj": $('#email'),
            "empty": false
        },
        "comment": {
            "obj": $('#comment'),
            "empty": false
        },
    };

    //On fait le Submit nous même pour gérer les erreures et submit en ajax
    commentForm.on('submit', function (e) {
        e.preventDefault();
        formerror = false;
        //on parcours les champs obligatoires
        $.each(fields, function (key, value) {
            value.obj.parent().find('.frm_error').remove(); //On enlève l'erreur
            fields[key]['empty'] = false;

            if (value.obj.val() == "") { //Si le champ est vide
                fields[key]['empty'] = true;
                value.obj.parent().append(errorMessage); //On rajoute l'erreur
                formerror = true;
            } else {
                //Vérification de la validité de l'email
                if (key == "email" && !validateEmail(value.obj.val())) {
                    fields[key]['empty'] = true;
                    value.obj.parent().append(errorMessageMail); //On rajoute l'erreur
                    formerror = true;
                }
            }
        });

        //SI on a pas d'erreur dans le form on le submit en ajax
        if (!formerror) {

            //Ajout l'animation de loading
            commentForm.addClass('loading');

            var formdata = commentForm.serialize();

            $.ajax({
                type: 'POST',
                url: '/wp-comments-post.php',
                data: formdata,
                success: function (res, textStatus) {
                    if (textStatus === "success") {
                        commentForm.html(succesMessage);
                    }
                }
            });
        }


    });
}


function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}