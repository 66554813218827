// function initBrochuresSlider() {
//     var sliders = document.querySelectorAll('.bloc-brochures .th-slider');
//     for (i = 0; i != sliders.length; i++) {
//         var slider = sliders[i];
//         initBrochures(slider);
//     }
//
//     function initBrochures(slider) {
//         // console.log("sliders", sliders);
//         var parent = slider.parentNode;
//         while (!parent.className.match(/th-slider-wrapper/)) {
//             parent = parent.parentNode;
//         }
//         var navButtons = parent.querySelectorAll('.nav-buttons button');
//         var dotsWrapper = parent.querySelectorAll('.nav-dots');
//
//
//         var reverse = 0;
//         if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
//             reverse = 1;
//         }
//
//         var s = new thSlider(slider, {
//             draggable: true,
//             rtl: reverse,
//             scrollListener: true,
//             scrollModeMaxWidth: 1024,
//             oninit: function (slider) {
//
//                 // console.log('scrollModeMaxWidth', this.scrollModeMaxWidth);
//
//                 if (navButtons.length === 2) {
//                     thSliderTools.onInitNav(slider, navButtons);
//                 }
//
//                 if (dotsWrapper) {
//                     thSliderTools.onInitDots(slider, dotsWrapper[0]);
//                 }
//
//             },
//             onchange: function (slider) {
//
//                 thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
//
//                 if (dotsWrapper) {
//                     thSliderTools.onChangeDots(slider, dotsWrapper[0]);
//                 }
//             }
//         });
//
//     }
// }
//
//
// function isSliderBrochuresReady() {
//     if (typeof thSlider === "undefined") {
//         setTimeout(isSliderBrochuresReady, 1000);
//         return false;
//     }
//     initBrochuresSlider();
// }
//
// isSliderBrochuresReady();
//
