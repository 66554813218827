var calendarDateFormat = 'DD/MM/YYYY';
var calendarDateLanguage = ($('html').attr('lang')).substring(0,2);
var startDate = moment(new Date()).format(calendarDateFormat);

/** Calendrier Formidable de base  */
$('.bloc-form input.frm_date, .bloc-form input.date').each(function(){
    if(!isTouchDevice()){
        $(this).attr('type','text');
        $(this).attr('placeholder','');
        $(this).dateRangePicker({
            inline: false,
            singleDate : true,
            alwaysOpen: false,
            singleMonth: true,
            showShortcuts: false,
            showTopbar: false,
            monthSelect: true,
            yearSelect: [2020, 2030],
            startOfWeek: 'monday',
            startDate: startDate,
            customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
            customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
            format: calendarDateFormat,
            language: calendarDateLanguage,
            setValue: function (s, s1, s2, inst) {

                $(this).parent().addClass("has-content");
                $(this).val(s1);
                if (!$(this).hasClass('no-refresh')) {
                    $(this).trigger('force-change');
                }
            },
        });
    }else{
        $(this).attr('type','date');
    }
});

/** Gestion des calendriers de filtres synchronisé avec plage de dates */
var $rangeCalendarAlwaysOpen = $('#calendar-range');
var $rangeCalendarOverlayMobile = $('#calendar-range-mobile');
var $rangeInputFrom = $('[name=dr_du]','#overlay-filtres');
var $rangeInputTo = $('[name=dr_au]','#overlay-filtres');
//var $spefilters = $('[name=dr_spe]','#overlay-filtres');
var $rangeInputFromMea = $('[name=dr_du]','.calendrier-listing-agenda');
var $rangeInputToMea = $('[name=dr_au]','.calendrier-listing-agenda');
//var $spefiltersMea = $('[name=dr_spe]','.calendrier-listing-agenda');

// $spefilters.on('change',function () {
//     var data_date = $(this).data();
//     calendarListingData.set(data_date.start,data_date.end);
// })

$rangeInputFrom.on('change', function () {
    if(!$rangeInputFrom.val()){
        $rangeInputFrom.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFrom.val(),$rangeInputTo.val());
});
$rangeInputTo.on('change', function () {
    if(!$rangeInputTo.val()){
        $rangeInputTo.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFrom.val(),$rangeInputTo.val());
});
$rangeInputFromMea.on('change', function () {
    if(!$rangeInputFromMea.val()){
        $rangeInputFromMea.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFromMea.val(),$rangeInputTo.val());
});
$rangeInputToMea.on('change', function () {
    if(!$rangeInputToMea.val()){
        $rangeInputToMea.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFromMea.val(),$rangeInputToMea.val());
});


// calendrier toujours affiché des listings agenda
if ($rangeCalendarAlwaysOpen.length > 0) {
    $rangeCalendarAlwaysOpen.dateRangePicker({
        inline: true,
        container: '#calendar-range',
        alwaysOpen: true,
        singleMonth: true,
        showShortcuts: false,
        showTopbar: false,
        startOfWeek: 'monday',
        startDate: moment().format(calendarDateFormat),
        hoveringTooltip: false,
        customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
        customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
        format: calendarDateFormat,
        language: calendarDateLanguage,
        setValue: function (s, s1, s2) {
            calendarListingData.set(s1.split('/').reverse().join('-'), s2.split('/').reverse().join('-'));
        }
    });
}

var calendarListingData = {

    from: null,
    to: null,
    submit: true,

    timeout: null,

    set: function (from, to, submit) {

        if(typeof submit != 'undefined') {
            calendarListingData.submit = submit;
        }

        if (from.match(/\//)) {
            from = from.split('/').reverse().join('-');
        }
        if (to.match(/\//)) {
            to = to.split('/').reverse().join('-');
        }
        if (from !== calendarListingData.from || to !== calendarListingData.to) {
            calendarListingData.from = from;
            calendarListingData.to = to;

            calendarListingData.onchange();
            return true;
        }
        return false;
    },

    onchange: function () {

        clearTimeout(calendarListingData.timeout);
        calendarListingData.timeout = setTimeout(function () {
            calendarListingData.onchangeDelais();
        }, 100);

    },

    onchangeDelais: function () {

        if(calendarListingData.from && calendarListingData.to) {
            $rangeInputFrom.val(calendarListingData.from);
            $rangeInputTo.val(calendarListingData.to);
            $rangeInputFromMea.val(calendarListingData.from);
            $rangeInputToMea.val(calendarListingData.to);
        }

        // force la soumission des filtres
        // if(calendarListingData.submit) {
        //     $rangeInputFrom.trigger('force-change');
        // }

        var fromSlash = calendarListingData.from.split('-').reverse().join('/');
        var toSlash = calendarListingData.to.split('-').reverse().join('/');

        if ($rangeCalendarOverlayMobile.length > 0) {
            $rangeCalendarOverlayMobile.data('dateRangePicker').setDateRange(fromSlash, toSlash);
        }
        if ($rangeCalendarAlwaysOpen.length > 0) {
            $rangeCalendarAlwaysOpen.data('dateRangePicker').setDateRange(fromSlash, toSlash);
        }


    }
};

var on_load_from = $rangeInputFrom.val();
var on_load_to = $rangeInputTo.val();

if(on_load_from && on_load_to){
    calendarListingData.set(on_load_from, on_load_to, false);
    calendarListingData.submit = true;
}