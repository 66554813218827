var overlaySearch = $('#overlay-search');
var inputSearch = $('#search');

// Gestion de l'affichage de la barre de navigation qui doit remonter en haut grâce aux classes appliquées
th_overlay.addCallbackOpen(function (overlayId) {
    // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
    if (overlayId === 'overlay-search') {
        setTimeout(function () {
            inputSearch.focus()
        }, 200);

    }
});