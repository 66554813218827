var btnMenu = $(".btn-menu");
var barreNavigation = $("#barre-navigation");
var toolbar = $("#toolbar");
var menu = $('#overlay-menu');


th_overlay.addCallbackOpen(function (menu) {

    if (menu === "overlay-menu") {
        btnMenu.addClass("menu-is-open");
        barreNavigation.addClass("menu-is-open");
        toolbar.addClass("menu-is-open");

        // pr-ouverture du premier lien si on est pas sur mobile
        if (document.body.clientWidth > thConfig.mobileBreakpoint){
            $('input[name="menu-pricipal"]').each(function(){
                if($("ul.item-"+$(this).val()).length > 0){
                    $(this).prop("checked", true);
                    return false;
                }
            });
        }

    }
});

th_overlay.addCallbackClose(function (menu) {

    if (menu === "overlay-menu") {
        btnMenu.removeClass("menu-is-open");
        barreNavigation.removeClass("menu-is-open");
        toolbar.removeClass("menu-is-open");
        // fermeture du menu de second niveau si on est en mobile
        if (document.body.clientWidth <= thConfig.mobileBreakpoint){
            $('#lvl2-0').prop("checked", true);
        }
    }

});
