(function(){

    // transformation des menus en accordéons une fois en mobile
   if (isMobileOrSmaller() && $('.widget-menu').length > 0){
       $('.widget-menu').each(function(){
           var self = $(this)
           var menu = $('.menu',self);
           var head = $('.head',self);
           var ulHeight = $('.menu',self).outerHeight() + "px";
           menu.attr("data-maxheight",ulHeight).css("max-height",'0').addClass('parsed');

           head.on("click",function(){
               self.toggleClass("open");
               if (self.hasClass("open")){
                   menu.css("max-height",menu.attr("data-maxheight"));
               }else{
                   menu.css("max-height",'0px');
               }
           });
       });
   }

})();