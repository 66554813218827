// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 120) {
        $(this).parent().addClass('view-more-active');

        btnShowMorePropos.on('click', function () {
            $(this).prev().toggleClass('toggle-height');
        });
    }
});


// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


// Init du slider dans les fiches SIT

function initAllSliders() {
    var sliders = document.querySelectorAll('.single-sit .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;

        if (slider.className.match(/reverse/) && document.body.clientWidth > thConfig.tabletPaysageBreakpoint) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: '1199',
            oninit: function (slider) {

                console.log("init", slider.el);
                console.log("parent", parent);
                console.log("navbuttons", navButtons);

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }

            }
        });

    }
}


function isSliderReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderReady, 1000);
        return false;
    }
    initAllSliders();
}

isSliderReady();

// Smooth scroll vers la carte - au click sur la miniature de la carte dans la barre sit

$(document).ready(function () {

    $("#carte-anchor").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 80
            }, 800, function () {
                window.location.hash = hash;
            });
        } // End if
    });


    $("[href='#horaires']").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 80
            }, 800, function () {
                window.location.hash = hash;
            });
        } // End if
    });
});


$('.js-links-dropdown').on('click', function(e){
    e.preventDefault();
    $(this).toggleClass('open');
})

$('.btn-iti-open-map').on('click', function(e){
    e.preventDefault();
    $('.sit-iti__map').toggleClass('open');
});

$('.btn-iti-list-open-map').on('click', function(e){
    e.preventDefault();
    $('.page-listing-itineraire .wrapper-map').toggleClass('open');
})



// @todo feature/evo-itineraires initialisation du canvas
if ($('#denivele').length > 0) {

    // Chart.js, lourd (170ko) mais beaucoup d'option dont des interractions
    // https://www.chartjs.org/

    //Récupération de la couleur de la page, et rajout de 80 à la fin du #hexa pour mettre la transparent de 50% pour le fill
    var chartColors = "#1D71B8";
    var bgChartCurve = "#1D71B840";

    //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
    var YAxis = $('#denivele').attr('data-YAxis').split(',');
    //# 13039 axe des ordonnées minimum à 100
    YAxis.push(100);
    var XAxis = $('#denivele').attr('data-XAxis').split(',');
    var ZMin = parseInt($('#denivele').attr('data-ZMin')) - 10;
    var ZMax = parseInt($('#denivele').attr('data-ZMax')) + 10;

    var ctx = $('#denivele');
    var myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: XAxis,
            datasets: [
                {
                    label: 'DÉNIVELÉ (m)',
                    data: YAxis,
                    borderColor: chartColors,
                    fill: true,
                    backgroundColor: bgChartCurve,
                    pointRadius: 0,
                    pointHoverRadius: 5,
                    pointHitRadius: 10,
                    lineTension: 0.1,
                }
            ]
        },

        hover: {
            mode: 'index'
        },
        options: {
            responsive: true,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10000
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'DISTANCE (km)',
                        fontFamily: 'Rubik'
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: false,
                        min: ZMin,
                        max: ZMax,
                        stepSize: 10000 // pour avoir un seul step comme sur la maquette (peu de chance qu'on ai une élévation de plus de 10km)
                    },
                    gridLines: {
                       // drawOnChartArea: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Alt (m)',
                        fontFamily: 'Rubik',
                        padding: {
                            bottom: 0
                        }
                    }
                }],
            },
            tooltips: {
                bodyFontFamily: 'Rubik',
                titleFontFamily: 'Rubik',
                displayColors: false,
            }
        },
    });

    document.getElementById("denivele").onmousemove = function (evt) {
        var activePoint = myChart.getElementAtEvent(event);

        // make sure click was on an actual point
        if (activePoint.length > 0) {
            $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
            //$('#overlay-itineraire-etape').addClass('open');
            // $('.shadow-overlay').addClass('open');
        } else {
            $('[data-markerindex]').removeClass('active');
        }
    };
}