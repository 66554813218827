// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topleft');
}

// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '#map-listing',
        nb_filters: '#filtres-actifs',
    },
    scrollTop: 0,
});

// listing CPT experiences
if ($('.js-listing-scrolltop').length > 0) {
    var scrolltop = $('.js-listing-scrolltop').offset().top;
}

// listing SIT ITI - map dans listing +  map dans overlay en mobile
var listingSitITI = new thListingManager({
    selectors: {
        listing: '.page-listing-itineraire',
        map: '#map-listing-iti',
        nb_filters: '#filtres-actifs'
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: true,
    }
});

var listingCPT = new thListingManager({
    selectors: {
        nb_filters: '#filtres-actifs',
    },
    scrollTop: scrolltop - 100
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
        }, 250);
    }
});

// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}




