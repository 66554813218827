var blocCarteInteractive = {
    bloc: document.querySelector(".bloc-carte-interactive"),
    slider: null,
    cards: null,
    map: null,
    mapCities: null,

    init: function () {
        this.cards = $(".slide", this.slider);
        this.map = $(this.bloc).find("#carte-interactive");
        this.mapCities = $(this.map).find(".city-name");
        this.initSlider();
        this.goToCard();
    },

    initSlider: function () {
        if (this.bloc) {
            var slider = this.bloc.querySelector(".th-slider");

            var navButtons = this.bloc.querySelectorAll(".nav-buttons button");

            if (slider) {
                this.slider = new thSlider(slider, {
                    draggable: true,
                    scrollListener: true,

                    oninit: function (slider) {

                        if (navButtons.length === 2) {
                            thSliderTools.onInitNav(slider, navButtons);
                        }


                    },
                    onchange: function (slider) {

                        if (navButtons.length === 2) {
                            thSliderTools.onChangeNav(slider, navButtons);
                        }
                    }
                });
            }
        }
    },

    goToCard: function () {

        var _self = this;

        $.each(this.mapCities, function (k, v) {

            var city = $(this).attr("data-city");

            $(this).on("click", function (e) {

                _self.changeAspect(e.currentTarget);

                var singleCard = $('.slide[data-city="' + city + '"]');

                _self.slider.goto(singleCard.index());

            });

        });
    },

    changeAspect: function (element) {
        var currentElement = $(element);

        $.each(this.mapCities, function (k, v) {
            $(this).attr("font-size", 12);
        });

        currentElement.attr("font-size", 14);

    },
}

blocCarteInteractive.init();