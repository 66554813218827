var btnSearch = $('.link-toolbar [data-overlay-open]');
var btnSearchClose = $('.btn-search-close');
var barreDeNavigation = $('#barre-navigation');
var overlaySearch = $('#overlay-meteo');


// Gestion de l'affichage de la barre de navigation qui doit remonter en haut grâce aux classes appliquées
th_overlay.addCallbackOpen(function (overlayId) {
    // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
    if (overlayId === 'overlay-meteo') {
        barreDeNavigation.addClass("is-open meteo-is-open");
    }
});

th_overlay.addCallbackClose(function (overlayId) {
    // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
    if (overlayId === 'overlay-meteo') {
        barreDeNavigation.removeClass("is-open meteo-is-open");
    }
});

if (overlaySearch.hasClass("open")) {
    barreDeNavigation.addClass("is-open meteo-is-open");
} else {
    barreDeNavigation.removeClass("is-open meteo-is-open");
}