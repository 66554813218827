(function () {


    $("select",$(".bloc-form")).closest(".form-field").addClass("form-field-select");
    $('input[type="file"]',$(".bloc-form")).addClass('jfilestyle');

    // gère une classe sur le parent quand un input est focused ou qu'il contiens une valeur
    // Utilisé pour le style des labels
    var inputs = $("input[type='text'],input[type='email'], textarea",$(".bloc-form"));
    for (i = 0; i != inputs.length; i++) {
        var input = inputs[i];
        inputState(input);
    }

    function inputState(input) {
        var parent = $(input).closest('.form-field');

        if ($(input).val()){
            parent.addClass("active");
        }

        $(input).on("focus",function(){
            parent.addClass("active");
        });

        $(input).on("blur",function(){
            if (!$(this).val()){
                console.log("empty");
                parent.removeClass("active");
            }
        });
    }

})();